<script setup lang="ts">
    import { useI18n } from 'vue-i18n';
    import { computed, ref } from 'vue';
    import { useForm } from 'vee-validate';
    import { object, string } from 'zod';
    import Accordion from 'primevue/accordion';
    import AccordionTab from 'primevue/accordiontab';
    import RadioButton from 'primevue/radiobutton';
    import FormField from '@/components/form-components/FormField.vue';
    import InputText from 'primevue/inputtext';
    import Button from 'primevue/button';
    import { toTypedSchema } from '@vee-validate/zod';
    import { hasValidationError } from '@/util/hasValidationError';
    import { Navigation } from '@/router/navigation';
    import router from '@/router';
    import { useMarketQuery } from '@/composables/market';
    import { stringValueOfParam } from '@/util/param';
    import { useRoute } from 'vue-router';
    import ErrorTag from '@/components/ErrorTag.vue';
    import { customerApi } from '@containex/portal-backend-api-client';
    import { SendCustomerActivationMailResponseStates } from '@containex/portal-backend-dto';
    import { httpClient } from '@/common/api/http-client';

    const { t } = useI18n();
    const language = stringValueOfParam(useRoute().params.language);
    const { market } = useMarketQuery();

    const marketLowercase = computed(() => market.value?.code.toLowerCase());
    const selectedOption = ref(0);
    const showError = ref(false);

    const schema = object({
        email: string().email(t('ERROR.INVALID_EMAIL')),
        companyId: string(),
    });

    const { errors, defineField, validate, values } = useForm({
        validationSchema: toTypedSchema(schema),
    });

    const [companyId] = defineField('companyId');
    const [email] = defineField('email');

    async function sendCustomerActivationMail(): Promise<void> {
        try {
            const { valid } = await validate();

            if (valid) {
                const { data } = await customerApi.sendCustomerActivationMail(httpClient, {
                    companyId: values.companyId ?? '',
                    email: values.email ?? '',
                    language: language ?? '',
                    market: marketLowercase.value ?? '',
                });

                if (data.state === SendCustomerActivationMailResponseStates.match) {
                    await router.push({ name: Navigation.FastTrackRegistrationSuccess });
                } else if (data.state === SendCustomerActivationMailResponseStates.companyIdExists) {
                    await router.push({ name: Navigation.NewRegistrationSuccess });
                } else {
                    showError.value = true;
                }
            }
        } catch {
            showError.value = true;
        }
    }

    async function onSubmit(): Promise<void> {
        showError.value = false;
        switch (selectedOption.value) {
            case 0: {
                await sendCustomerActivationMail();
                break;
            }
            case 1: {
                await router.push({ name: Navigation.Signup });
                break;
            }
        }
    }
</script>

<template>
    <div class="signup-selection-container">
        <h2>{{ t('LOGIN.LOGIN_SIGNUP_SELECTION.TITLE') }}</h2>
        <p>{{ t('LOGIN.LOGIN_SIGNUP_SELECTION.DESCRIPTION') }}</p>

        <Accordion v-model:active-index="selectedOption" :multple="false">
            <template #collapseicon></template>
            <template #expandicon></template>
            <AccordionTab
                :pt="{
                    headerAction: {
                        class: [
                            selectedOption === 0 ? 'header-action-active-0' : '',
                            showError ? 'accordion-header-error' : '',
                        ],
                        style: 'background-color: white',
                    },
                    content: {
                        class: showError ? 'accordion-content-error' : '',
                    },
                }"
            >
                <template #header>
                    <RadioButton id="existingCustomerSelection" v-model="selectedOption" :value="0" />
                    <label for="existingCustomerSelection">
                        {{ t('LOGIN.LOGIN_SIGNUP_SELECTION.SELECTION_EXISTING_CUSTOMER_NUMBER') }}
                    </label>
                </template>
                <div class="content-container">
                    <p>
                        {{ t('LOGIN.LOGIN_SIGNUP_SELECTION.EXISTING_CUSTOMER_NUMBER_DESCRIPTION') }}
                    </p>
                    <form @submit.prevent>
                        <FormField :label="t('FORM.CUSTOMER_NUMBER')" :error-message="errors.companyId">
                            <InputText
                                v-model="companyId"
                                :invalid="hasValidationError(errors.companyId) && selectedOption === 0"
                                :class="showError ? 'error-border' : ''"
                            />
                        </FormField>
                        <FormField :label="t('FORM.EMAIL')" :error-message="errors.email">
                            <InputText
                                v-model="email"
                                :invalid="hasValidationError(errors.email) && selectedOption === 0"
                                :class="showError ? 'error-border' : ''"
                            />
                        </FormField>
                    </form>
                    <ErrorTag v-if="showError" :message="t('LOGIN.LOGIN_SIGNUP_SELECTION.CUSTOMER_NOT_FOUND')" />
                </div>
            </AccordionTab>
            <AccordionTab
                :pt="{
                    headerAction: {
                        class: selectedOption === 1 ? 'header-action-active-1' : '',
                        style: 'background-color: white',
                    },
                    content: { style: 'display: none' },
                }"
            >
                <template #header>
                    <RadioButton id="nonExistingCustomerSelection" v-model="selectedOption" :value="1" />
                    <label for="nonExistingCustomerSelection" data-testid="signup-selection-new-registration">
                        {{ t('LOGIN.LOGIN_SIGNUP_SELECTION.SELECTION_NON_EXISTING_CUSTOMER_NUMBER') }}
                    </label>
                </template>
                <div v-show="false"></div>
            </AccordionTab>
        </Accordion>
        <Button
            class="button"
            :label="t('LOGIN.LOGIN_SIGNUP_SELECTION.CONTINUE')"
            data-testid="signup-selection-continue-button"
            @click="onSubmit()"
        />
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    label {
        cursor: pointer;
    }

    @include main.for-breakpoint-md {
        .signup-selection-container {
            margin: main.$spacing-8 auto 0;

            & button {
                width: unset;
            }
        }

        .content-container {
            & input {
                width: 50%;
            }
        }
    }

    .signup-selection-container {
        background: main.$vt-c-white;
        padding: main.$spacing-6;
        max-width: main.$breakpoint-md;

        :deep(.header-action-active-0) {
            border-color: main.$ctx-primary-color;
            border-bottom: none;

            &.accordion-header-error {
                border-color: main.$color-error-red;
            }
        }

        :deep(.header-action-active-1) {
            border-color: main.$ctx-primary-color;
            border-top: 1px solid;
        }

        :deep(.p-accordion-content) {
            border-color: main.$ctx-primary-color;
            padding-top: 0;

            &.accordion-content-error {
                border-color: main.$color-error-red;
            }
        }

        :deep(.error-border) {
            border-color: main.$color-error-red;
        }
    }

    .heading {
        font-weight: 700;
    }

    .p-accordion-tab {
        & label {
            margin-left: main.$spacing-4;
        }

        & p {
            margin-bottom: main.$spacing-4;
            margin-top: 0;
        }

        & .form-field-container {
            padding-top: main.$spacing-6;
        }
    }

    .content-container {
        margin-left: main.$spacing-7;
    }

    input {
        width: 100%;
    }

    .button {
        margin-top: main.$spacing-5;
        width: 100%;
    }
</style>
